/**
 * Function to add format currency
 *
 * @param  {String} value
 * @param  {Object} currency
 * @return {String}
 */
export function formatCurrency(value, currency) {
  return "" ? value === null : formatNumber(value, currency);
}


/**
 * Function to add format number with currency
 *
 * @param  {String} value
 * @param  {Object} currency
 * @return {String}
 */
function formatNumber(value, currency) {
  if (value === null) {
    return "";
  }

  if (currency.forceDecimals === null ) {
    currency.forceDecimals = true;
  }

  const precision = currency.precision ? currency.precision : 2;
  const decimal = currency.decimal || ".";
  const thousand = currency.thousand || "";

  const power = 10 ** precision;
  const number = ((Math.round(value * power)) / power).toFixed(precision);
  const base = "" + parseInt((Math.abs(number || 0)).toFixed(precision), 10);
  const mod = base.length > 3 ? base.length % 3 : 0;

  let num = [];
  if (mod > 0) {
    num.push(`${base.substr(0, mod)}${thousand}`);
  }
  (num.push ((base.substr(mod)).replace(/(\d{3})(?=\d)/g, `$1${thousand}`)));
  if (precision > 0) {
    const dec = (number.split("."))[1];
    if (currency.forceDecimals || parseInt(dec, 10) > 0) {
      num.push(`${decimal}${dec}`);
    }
  }

  num = (currency.format.replace(/%s/g, currency.symbol)).replace(/%v/g, num.join(""));
  if (parseFloat(value) < 0) {
    num = `-${num}`;
  }
  return num;
}
