/**
 * Function to add params to endpoint
 *
 * @param  {String} endpoint
 * @param  {Object} params
 * @return {String}
 */
 export function addUrlParams(endpoint, params = {}) {
  const query = `${new URLSearchParams(params)}`;
  return !!query ? `${endpoint}?${query}` : endpoint;
}


/**
 * Function to send request to given url and with specified payload
 *
 * @param  {String} url
 * @param  {Object} payload
 * @return {Object}
 */
export function request(url, payload = null) {
  const options = !payload
    ? {
        method: "GET",
        mode: "cors"
      }
    : {
        method: "POST",
        mode: "cors",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(payload)
      };
  return fetch(url, options)
    .then(response => {
      if(response.ok) {
        return response.json(); // JSON response into native JavaScript objects
      } else {
        throw "Incorrect response from server"
      }
    });
}
