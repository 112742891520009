// @ts-check


import { html, LitElement } from 'lit';
import Mustache from 'mustache';
import { query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';

import { Carousel } from "./carousel.js";
import { arrowLeft, arrowRight } from "./icons";
import { templateArrowLeft, templateArrowRight } from "./template_icons";
import { addUrlParams, request } from "./recommendations.js";
import { formatCurrency } from "./util.js";

import recommendations from "./css/recommendations.scss";
import { getOrCreateSessionId, localStorageAdapter } from "./session";
import { buildUrl } from "./environment/production";

class RecommendationsWidget extends LitElement {
  static styles = [
    recommendations
  ];

  static get properties() {
    return {
      baseUrl: { type: String, state: true },
      brand: { type: String, state: true },
      description: { type: String, state: true },
      results: { type: Array, state: true },
      sessionId: { type: String, state: true },
      title: { type: String, state: true },
      currencyDecimal: { type: String },
      currencyFormat: { type: String, attribute: 'currency-format' },
      currencyLabel: { type: String },
      currencyPrecision: { type: String },
      currencySymbol: { type: String, attribute: 'currency-symbol' },
      currencyThousand: { type: String },
      currencyForceDecimals: { type: String, attribute: 'currency-force-decimals' },
      currentUrl: { type: String, attribute: 'current-url' },
      hashid: { type: String, attribute: 'hashid' },
      installationId: { type: String, attribute: 'installation-id' },
      region: { type: String },
      totalProducts: { type: String, attribute: 'total-products' },
      widgetId: { type: String, attribute: 'id' },
      widget_id: { type: String, attribute: 'widget_id' },
    };
  }

  constructor() {
    super();
    this.carousel = Carousel;
    this.payload = {};
    this.results = [];
    this.sessionId = null;
    this.hashid = "123hashid";
    this.widgetId = "1";
    this.currencyDecimal = ".";
    this.currencyFormat = undefined;
    this.currencyLabel = "USD";
    this.currencyPrecision = 2;
    this.currencySymbol = undefined;
    this.currencyThousand = ",";
    this.currencyForceDecimals = "true";
    this.currentUrl = window.location.href.split('?')[0];
    this.title = "";
    this.description = "";
    this.brand = "";
    this.totalProducts = "10";
    this.installationId = null;
    this.baseUrl = buildUrl(this.region);
  }

  @query('#dfwidget-card-list', true)
  _cardList;

  _region = "eu1";

  set region(val) {
    this._region = val;
    this.baseUrl = buildUrl(this.region);
  }

  get region() {
    return this._region;
  }

  get endpoint() {
    if (this.widget_id !== undefined) {
      return `${this.baseUrl}v2/${this.widget_id}`;
    } else {
      return `${this.baseUrl}${this.hashid}`;
    }
  }

  get currency() {
    return {
      decimal: this.currencyDecimal,
      format: this.currencyFormat,
      label: this.currencyLabel,
      precision: this.currencyPrecision,
      symbol: this.currencySymbol,
      thousand: this.currencyThousand,
      forceDecimals: this.currencyForceDecimals.toLowerCase() === 'true',
    }
  }

  get sessionKey() {
    return this.hashid;
  }

  get sessionAdapter() {
    return localStorageAdapter;
  }

  /**
   * Render template without shadow DOM.
   * Note that shadow DOM features like
   * encapsulated CSS and slots are unavailable.
   */
  createRenderRoot() {
    return this;
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('click', this);
    this.sessionId = getOrCreateSessionId(this.sessionKey, this.sessionAdapter);
    this.loadRecommendations();
  }


  decodeEntity(inputStr) {
    let textarea = document.createElement("textarea");
    textarea.innerHTML = inputStr;
    return textarea.value;
  }

  /**
   * Function to send request to recommendation system endpoint and load results
   *
   * @param  {Object} payload
   */
  loadRecommendations(payload = null) {
    const params = {
      rpp: this.totalProducts,
      current_url: this.currentUrl,
      title: this.title,
      description: this.description,
      brand: this.brand,
      session_id: this.sessionId
    };

    console.log(params)
    const url = addUrlParams(this.endpoint, params);

    request(url, payload)
      .then(response => {
        if (response.currency !== undefined) {
          this.currencyDecimal = response.currency.decimal;
          if (!this.currencyFormat) {
            this.currencyFormat = response.currency.format;
          }
          this.currencyLabel = response.currency.label;
          this.currencyPrecision = response.currency.precision;
          if (!this.currencySymbol) {
            this.currencySymbol = this.decodeEntity(response.currency.symbol);
          }
          this.currencyThousand = response.currency.thousand;
        }
        this.results = response.results || [];
      })
      .catch(err => {
        console.log(err);
        this.setAttribute("hidden", "");
      });
  }

  /**
   * Display previous products in the carousel
   */
  previousPage() {
    this.carousel.previousPage(this._cardList);
  }

  /**
   * Display next products in the carousel
   */
  nextPage() {
    this.carousel.nextPage(this._cardList);
  }

  /**
   * Function to handle item click side effects
   *
   * @param {any} result
   * @returns {boolean}
   */
  clickResult({ dfid }) {
    this.registerEvent('widget-click', dfid)
    return true;
  }

  /**
   * Function to register user events for statistics.
   *
   * @param {String} event
   * @param {String} [dfid]
   */
  registerEvent(event, dfid) {
    const reqParams = {
      hashid: this.hashid,
      session_id: this.sessionId,
      event_type: event,
    };

    if (!!dfid) {
      reqParams.dfid = dfid;
    }

    const url = addUrlParams(`${this.endpoint}/stats/${event}`, reqParams);

    request(url).catch(console.error);
  }

  disconnectedCallback() {
    this.removeEventListener('click', this);
    super.disconnectedCallback();
  }

  /**
   * Helper function to handle click
   * on left/right carousel arrows
   *
   * @param {any} eventTarget
   * @param {string} arrow
   * @returns {boolean}
   */
  detectCarouselArrowClick(eventTarget, arrow) {
    return eventTarget.id === `dfwidget-carousel-arrow-${arrow}`
      || eventTarget.classList.contains(`df-carousel-arrow-${arrow}`)
      || eventTarget.closest(`.df-carousel-arrow-${arrow}`) !== null;
  }

  /**
   * Handle events of mustache template
   *
   * @param {any} event
   */
  handleEvent(event) {
    switch (event.type === 'click') {
      case this.detectCarouselArrowClick(event.target, "right"):
        this.nextPage();
        break;
      case this.detectCarouselArrowClick(event.target, "left"):
        this.previousPage();
        break;
      case event.target.classList.contains('dfwidget-card-item-link'):
        this.clickResult(this.results[event.target.dataset.indexNumber]);
        break;
      default:
        break;
    }
  }

  render() {
    if (this.results.length > 0) {
      const template = document.getElementById(`dfwidget-template-carousel-${this.widgetId}`);

      // IF A TEMPLATE EXISTS IT IS RENDERED ELSE DEFAULT CAROUSEL IS RENDERED
      if (template) {
        const templateCarousel = template.innerHTML;
        const output = document.getElementById(`dfwidget-carousel-${this.widgetId}`);
        const currency = this.currency;
        let idx = 0;
        let results = this.results;

        results.forEach(result => {
          result.price_f = result.price
            ? formatCurrency(result.price, currency)
            : "";
          result.sale_price_f = result.sale_price
            ? formatCurrency(result.sale_price, currency)
            : "";
        });

        const data = {
          arrowLeft: templateArrowLeft,
          arrowRight: templateArrowRight,
          currency: this.currency,
          results: results,
          idx: function () {
            return idx++;
          }
        };

        const output_result_carousel = Mustache.render(templateCarousel, data);
        return output.insertAdjacentHTML('beforeend', output_result_carousel);

      } else {
        return html`
          <div class="dfwidget-host">
            <div class="dfwidget-carousel">
              <div class="carousel-arrow carousel-arrow-left" @click="${() => this.previousPage()}">${arrowLeft}</div>
              <div class="carousel-content" id="dfwidget-card-list">
              ${repeat(this.results, x => `item-${x.type}-${x.id}`, result => html`
                <div class="dfwidget-card" tabindex="0">
                  <div class="card-media">
                    <div class="card-media-thumbnail">
                      <img src=${result.image_link} alt=${result.title} onerror="this.parentNode.classList.add('card-media-thumbnail--notfound')">
                    </div>
                  </div>
                  <div class="card-content flexible">
                    <div class="card-title line-clamp" title=${result.title}>${result.title}</div>
                    <div class="card-description">
                      <div class="line-clamp">${result.description}</div>
                    </div>

                    <div class="flexible"></div>

                    ${ifDefined(result.price ? html`
                      <div class="card-pricing">
                        ${ifDefined(result.sale_price ? html`
                          <span class="card-price card-price--sale">
                            ${formatCurrency(result.sale_price, this.currency)}
                          </span>
                        ` : '')}
                        <span class="card-price">
                          ${formatCurrency(result.price, this.currency)}
                        </span>
                      </div>
                    ` : '')}

                    <a class="card-link" href=${result.link} @click="${() => this.clickResult(result)}"></a>
                  </div>
                </div>
              `)}
              </div>
              <div class="carousel-arrow carousel-arrow-right" @click="${() => this.nextPage()}">${arrowRight}</div>
            </div>
          </div>
        `;
      }

    }
  }
}

customElements.define("df-recommendations", RecommendationsWidget);
