import md5 from "md5";

export const localStorageAdapter = Object.freeze({
  get: ((k) => JSON.parse(localStorage.getItem(k))?.session_id),
  set: ((k, v) => {
    const sessionData = JSON.parse(localStorage.getItem(k)) || {};
    localStorage.setItem(k, JSON.stringify({...sessionData, session_id: v}))
  })
});

/**
 * Generic get or create session id with specified key, using provided adapter..
 *
 * @param {String} key
 * @param {Object} adapter
 * @return {String}
 */
export function getOrCreateSessionId(key, adapter) {
  const keyName = `${key}.widget`;
  let sessionId = adapter.get(keyName);
  if (!sessionId) {
    sessionId = generateSessionId();
    adapter.set(keyName, sessionId)
  }

  return sessionId;
}

function generateSessionId() {
  return md5([new Date().getTime(), String(Math.floor(Math.random() * 10000))].join(""));
}
