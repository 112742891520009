export const Carousel = {
  /**
   * Function to get visible children of carousel contentElement
   *
   * @param  {Object} contentElement
   */
  _getVisibleChildren(contentElement) {
    const parentRect = contentElement.getBoundingClientRect();
    return Array.from(contentElement.children)
                .filter(child => {
                  const childRect = child.getBoundingClientRect();
                  return parentRect.left <= childRect.left && parentRect.right >= childRect.right;
                });
  },
  /**
   * Function to scroll to next page children of carousel contentElement
   *
   * @param  {Object} contentElement
   */
  nextPage(contentElement) {
    const visible = this._getVisibleChildren(contentElement);

    if (visible.length > 0) {
      const lastChild = visible.slice(-1).pop();
      const nextChild = lastChild.nextElementSibling;

      if (nextChild) {
        const contentRect = contentElement.getBoundingClientRect();
        const lastChildRect = lastChild.getBoundingClientRect();
        const nextChildRect = nextChild.getBoundingClientRect();

        const distance = contentElement.scrollLeft + (lastChildRect.x - contentRect.x);
        const width = lastChildRect.width;
        const margin = nextChild ? (nextChildRect.x - lastChildRect.x - lastChildRect.width) : 0;

        contentElement.scroll({
          left: distance + width + (margin / 2),
          behavior: "smooth"
        });
      }
    }
  },
  /**
   * Function to scroll to previous page children of carousel contentElement
   *
   * @param  {Object} contentElement
   */
  previousPage(contentElement) {
    const visible = this._getVisibleChildren(contentElement);

    if (visible.length > 0) {
      const firstChild = visible[0];
      const prevChild = firstChild.previousElementSibling;

      if (prevChild) {
        const contentRect = contentElement.getBoundingClientRect();
        const firstChildRect = firstChild.getBoundingClientRect();
        const prevChildRect = prevChild.getBoundingClientRect();

        const distance = contentElement.scrollLeft - contentRect.width;
        const width = prevChildRect.width - (contentRect.x - prevChildRect.x);
        const margin = prevChild ? (firstChildRect.x - prevChildRect.x - prevChildRect.width) : 0;

        contentElement.scroll({
          left: distance + width + (margin / 2),
          behavior: "smooth"
        });
      }
    }
  }
};
